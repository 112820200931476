import classNames from "classnames";
import React, { ReactNode } from "react";
import styled, { css } from "styled-components";

import { HexTooltip } from "../../hex-components";

export const PresenceAvatarWidth = 26;

const Wrapper = styled.div<{
  $size: number;
}>`
  position: relative;

  display: flex;

  &:last-child {
    width: ${({ $size }) => `${$size}px`};
  }
`;

const StyledHexTooltip = styled(HexTooltip)`
  && {
    display: flex;
  }
`;

const Inner = styled.div<{
  $isActive: boolean;
  $size: number;
  $isCollapsed: boolean;
  $hasImage: boolean;
}>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  overflow: hidden;

  font-size: ${({ theme }) => theme.fontSize.SMALL};

  background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};

  border-radius: 50%;

  box-shadow: ${({ $hasImage, $isCollapsed, theme }) =>
    $hasImage || $isCollapsed
      ? "none"
      : `inset 0 0 0 1px ${theme.borderColor.DEFAULT}`};

  img {
    opacity: ${({ $isActive }) => (!$isActive ? "0.25" : "1")};

    transition: opacity ${({ theme }) => theme.animation.duration}
      ${({ theme }) => theme.animation.easing};
  }

  ${({ $isCollapsed, theme }) =>
    $isCollapsed &&
    css`
      color: ${theme.fontColor.MUTED};

      opacity: 1;
    `}

  ${({ $isCollapsed, theme }) =>
    $isCollapsed &&
    css`
      border: 1px solid ${theme.borderColor.MUTED};
    `}
`;

export interface AvatarProps {
  text: ReactNode;
  altText?: string;
  active: boolean;
  tooltipContent?: JSX.Element;
  size?: number;
  imageUrl?: string;
  className?: string;
  tooltip?: boolean;
  isCollapsed?: boolean;
}

/**
 * Generic avatar commponent with built-in tooltip support.
 *
 * For most use cases, you probably want `UserAvatar`, which is Hex aware.
 */
export const Avatar: React.ComponentType<AvatarProps> = React.memo(
  function Avatar({
    active,
    altText,
    className,
    imageUrl,
    isCollapsed = false,
    size = 28,
    text,
    tooltipContent,
  }) {
    const hasImage = imageUrl != null;
    const userImage = hasImage ? (
      <img alt={altText} src={imageUrl}></img>
    ) : (
      text
    );

    const content =
      tooltipContent != null ? (
        <StyledHexTooltip
          content={tooltipContent}
          hoverOpenDelay={0}
          maxWidthOverride={400}
        >
          <Inner
            $hasImage={hasImage}
            $isActive={active}
            $isCollapsed={isCollapsed}
            $size={size}
          >
            {userImage}
          </Inner>
        </StyledHexTooltip>
      ) : (
        <Inner
          $hasImage={hasImage}
          $isActive={active}
          $isCollapsed={isCollapsed}
          $size={size}
        >
          {userImage}
        </Inner>
      );

    return (
      <Wrapper $size={size} className={classNames(className, "fs-exclude")}>
        {content}
      </Wrapper>
    );
  },
);
