import { DateTimeString, UserId, sentenceCase } from "@hex/common";
import React from "react";
import styled from "styled-components";

import { HumanDate } from "../common/HumanDate";

import { Avatar } from "./Avatar";

const TooltipContentDiv = styled.div`
  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
`;

const TooltipNameDiv = styled.div`
  color: ${({ theme }) => theme.fontColor.DEFAULT};
`;

const TooltipMetaDiv = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin-top: 6px;
  overflow: hidden;

  line-height: 10px;
  white-space: nowrap;
`;

const TooltipRole = styled.div``;

const TooltipEmailDiv = styled.div`
  display: flex;

  text-overflow: ellipsis;
`;

const TooltipActiveDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
`;

const ActiveLabel = styled.label`
  margin-right: 4px;
`;

const StyledHumanDate = styled(HumanDate)`
  display: inline-block;
`;

const ActiveIndicator = styled.div`
  width: 5px;
  height: 5px;
  margin-right: 4px;

  background-color: ${({ theme }) => theme.intent.SUCCESS};
  border-radius: 50%;
`;

export interface UserProps {
  id?: UserId;
  name?: string;
  email?: string;
  imageUrl?: string;
  active?: boolean;
  role?: string;
  isSupportUser?: boolean;
}

export interface UserAvatarProps extends UserProps {
  lastActiveDate?: DateTimeString;
  size?: number;
  className?: string;
  tooltip?: boolean;
}

/**
 * Hex aware avatar component with reasonable default behavior.
 *
 * Does not request its own data, so needs to be manually wired up.
 */
export const UserAvatar: React.ComponentType<UserAvatarProps> = React.memo(
  function UserAvatar({
    active = true,
    className,
    email,
    imageUrl,
    isSupportUser,
    lastActiveDate,
    name,
    role,
    size,
    tooltip = true,
  }) {
    const avatarName = name || email;
    const activeStatus =
      lastActiveDate != null ? (
        active ? (
          <TooltipActiveDiv>
            <ActiveIndicator />
            <ActiveLabel>Active</ActiveLabel>
          </TooltipActiveDiv>
        ) : (
          <TooltipActiveDiv>
            <ActiveLabel>Last opened:</ActiveLabel>
            <StyledHumanDate date={lastActiveDate} />
          </TooltipActiveDiv>
        )
      ) : null;

    const tooltipContent = (
      <TooltipContentDiv>
        {name && <TooltipNameDiv>{name}</TooltipNameDiv>}
        <TooltipMetaDiv>
          {role && (
            <TooltipRole>
              {isSupportUser ? "Support " : ""}
              {sentenceCase(role)}
            </TooltipRole>
          )}
          {email && <TooltipEmailDiv>{email}</TooltipEmailDiv>}
        </TooltipMetaDiv>
        {activeStatus}
      </TooltipContentDiv>
    );

    const text =
      avatarName != null && avatarName.length > 0 ? avatarName.charAt(0) : "?";

    return (
      <Avatar
        active={active}
        altText={text}
        className={className}
        imageUrl={imageUrl}
        size={size}
        text={text}
        tooltipContent={tooltip ? tooltipContent : undefined}
      />
    );
  },
);
